@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"), url("../../fonts/Nunito.ttf") format("truetype");
}

body {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  color: #333333;
  text-align: center;
  padding-top: 150px;
}

h1 {
  font-size: 48px;
  margin-bottom: 30px;
}

p {
  font-size: 24px;
  margin-bottom: 40px;
}

a {
  color: #4c92d3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.me-30 {
  margin-right: 30px;
}

.d-flex_container {
  transform: translateX(-90px);
  display: flex;
  justify-content: center;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-radius: 30%;
    border-top: 5px solid #42aaff;
    margin-top: 0px;
    margin-bottom: -20px;
    padding: 0;
  }
}

.block_header {
  height: 250px;
  background-color: #4c92d3;
}

.block_content {
  margin-top: 50px;
  display: inline-block;
  width: 50%;
}

.d-flex_container_mobile {
  display: block;
  justify-content: center;

  hr {
    display: block;
    border-radius: 30%;
    border-top: 2px solid #42aaff;
    margin: 0 10% -15px 10%;
  }
}

.block_header_mobile {
  height: 150px;
  background-color: #4c92d3;
}

.block_content_mobile {
  margin-top: 35px;
  display: inline-block;
}

.container_desktop {
  margin-top: 120px;
}

.container_mobile {
  margin-top: 120px;
}

.font-Nunito {
  font-family: "NunitoBold";
}

.font-Roboto {
  font-family: "Roboto";
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs24 {
  font-size: 24px;
}

.fs38 {
  font-size: 38px;
}

.fs64 {
  font-size: 64px;
}

.fs128 {
  font-size: 128px;
}
.text-wh {
  color: white;
}
.d-flex {
  display: flex;
}

.geat__icon {
  transform-box: border-box;
  width: 169px;
  animation: rot linear 5s infinite;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(280deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
